<template>
  <p
    class="tw-text-center text-light-color tw-text-sm tw-font-normal"
  >
    Map weather overlay data sourced from the National Oceanic and Atmospheric Administration
    (<a
      class="link-color-brand"
      href="https://www.noaa.gov/"
      target="_blank"
    >NOAA</a>) and
    OpenSnow proprietary datasets.
    <br>
    Avalanche Forecasts sourced from
    <a
      class="link-color-brand"
      href="https://avalanche.org"
      target="_blank"
    >Avalanche.org</a>
    and
    <a
      class="link-color-brand"
      href="https://avalanche.ca"
      target="_blank"
    >Avalanche.ca</a>.
  </p>
</template>

<script>
export default {
  name: 'MapFooter',
};
</script>
