<template>
  <Dialog
    :show-dialog="showDialog"
    @close="handleClose"
  >
    <Card
      class="tw-w-full lg:tw-w-96"
      :has-body-padding="true"
      @close-button-click="handleClose"
    >
      <template #body>
        <h2 class="tw-mb-8 tw-text-xl tw-font-medium tw-text-center">
          <span>NEW: Lightning Distance Rings</span>
        </h2>
        <p class="tw-mb-4">
          Distance rings are now shown centered around your location, and you can tap the map to
          adjust the center point of the rings.
        </p>
        <p>
          <NuxtLink
            class="tw-underline tw-font-medium link-color-brand"
            target="_blank"
            to="https://support.opensnow.com/feature-guides/lightning-risk-map"
            @click="handleClose"
          >
            Learn More
          </NuxtLink>
        </p>
        <footer class="tw-py-1 tw-my-8">
          <Button
            class="tw-w-full"
            :display-block="true"
            :large="true"
            :type="ButtonTypes.primary"
            @click="handleClose"
          >
            Close
          </Button>
        </footer>
      </template>
    </Card>
  </Dialog>
</template>

<script setup>
/* eslint vue/multiline-html-element-content-newline: off */
import { ButtonTypes } from '@@/components/Common/Button.vue';

defineProps({
  showDialog: {
    type: Boolean,
    default: false,
  },
});

const $emit = defineEmits(['close']);
const handleClose = () => $emit('close');
</script>
